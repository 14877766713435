import { gql } from '@apollo/client';
import { DELIVERY_COUNTRY } from '../fragments/deliveryCountryFragment';
import { USER_PASPORT } from '../fragments/userPasportFragment';

export const QUERY_USERS = gql`
  ${DELIVERY_COUNTRY}
  ${USER_PASPORT}
  query users($offset: Int, $limit: Int, $search: String) {
    users(offset: $offset, limit: $limit, search: $search) {
      count
      rows {
        id
        email
        name
        company
        country {
          ...FragmentDeliveryCountry
        }
        address
        address2
        address3
        zipCode
        city
        state
        phone
        eoriCode
        status
        createdAt
        updatedAt
        discount
        balance
        passportData {
          ...FragmentUserPasport
        }
        entityLegalData {
          isDataVerified
          company
          id
          isDataVerified
        }
        creditLimit
        whFeePersonalDiscount
      }
    }
  }
`;
