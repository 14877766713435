import React from 'react';
import {
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { TRoutes } from '../../../utils/helpers';
import { DATE_FORMAT } from '../../../utils/constants';
import { BoxCentered } from '../../BoxCentered/BoxCentered';
import {
  ActionsItem,
  ConvertWarehouseStatus,
} from '../../../utils/helperComponents';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import TrackTrackNumberComponent from '../../TrackTrackNumberComponent/TrackTrackNumberComponent';
import { actionOptions } from './options';
import { IReturnsTableRowComponent } from './types';

const ReturnsTableRowComponent = ({ item }: IReturnsTableRowComponent) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const itemIdTitle = item?.barcodeId?.split('.')[0];
  const shipmentIdTitle = item?.shipmentOrderBarcodeId?.split('.')[0];
  const formattedCreatedAt = moment(Number(item?.createdAt)).format(
    DATE_FORMAT,
  );

  return (
    <TableRow>
      <TableCell align='center'>
        <Link
          to={`${TRoutes.RETURN_DETAILS_WITHOUT_ID}${item?.id?.toString()}`}
        >
          {itemIdTitle}
        </Link>
        <Typography className={classes.dateTitle}>
          {formattedCreatedAt}
        </Typography>
      </TableCell>
      <TableCell align='center'>
        <Link
          to={`${
            TRoutes.PARCELS_FROM_WAREHOUSE_DETAILS_WITHOUT_ID
          }${item.shipmentOrderId?.toString()}`}
        >
          {shipmentIdTitle}
        </Link>
      </TableCell>
      <TableCell align='center'>{item?.warehouse}</TableCell>
      <TableCell align='center'>{`${item?.cost} USD`}</TableCell>
      <TableCell align='center'>
        <BoxCentered>
          {item.trackNumber && item.carrierCode && (
            <TrackTrackNumberComponent
              carrierCode={item.carrierCode}
              trackNumber={item.trackNumber}
            />
          )}
        </BoxCentered>
      </TableCell>
      <TableCell align='center'>
        <ConvertWarehouseStatus status={item.warehouseStatus as string} />
      </TableCell>
      <TableCell align='center'>{item.acceptedQuantityItem}</TableCell>

      <TableCell align='center'>
        <Select value={t('app.action')}>
          <MenuItem
            value={t('app.action')}
            disabled
            style={{ display: 'none' }}
          >
            {t('app.action')}
          </MenuItem>
          {actionOptions.map((option) => (
            <ActionsItem
              {...option}
              key={option.title}
              itemLinkName={classes.actionMenuItem}
              linkClassName={classes.actionLink}
              iconClassName={`${classes.actionIcon} ${classes.actionIconWatch}`}
              link={`${
                TRoutes.RETURN_DETAILS_WITHOUT_ID
              }${item?.id?.toString()}`}
            />
          ))}
        </Select>
      </TableCell>
    </TableRow>
  );
};

export default ReturnsTableRowComponent;
