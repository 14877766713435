import React, { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  Input,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Else, If, Then } from 'react-if';
import { useMutation, useQuery } from '@apollo/client';
import { Pagination } from '@material-ui/lab';
import SearchIcon from '@material-ui/icons/Search';
import useStyles from './styles';
import UserRowItem from '../../components/UserRowItem';
import { LIMIT_ITEM_PAGE } from '../../utils/helpers';
import { QUERY_USERS } from '../../GraphQL/queries/getUsers';
import { MUTATION_USER_DISCOUNT_MANAGMENT } from '../../GraphQL/mutations/setUserDiscount';
import { ShowLoadingText } from '../../utils/helperComponents';
import {
  useVerifyPassportDataMutation,
  useVerifyLegalDataMutation,
  useUpdateCreditLimitForUserMutation,
  useGetCurrentUserQuery,
  User,
} from '../../generated/graphql';
import { ModalDiscount, useDiscount } from './discount';
import { useCredit, ModalCredit } from './creditLimit';
import { LOCAL_STORAGE_KEYS } from '../../utils/constants';
import { BoxCentered } from '../../components/BoxCentered/BoxCentered';
import { useTranslation } from 'react-i18next';

type HandleSetCreditLimitArg = {
  userId: number;
  value: number;
};

const itemsLimit = localStorage.getItem(LOCAL_STORAGE_KEYS.ITEMS_LIMIT);

export default function Users() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const [searchInputValue, setSearchInputValue] = useState('');

  const [users, setUsers] = useState<User[] | []>([]);
  const [filter, setFilter] = useState({
    search: '',
    page: 1,
    itemsLimit: itemsLimit ? +itemsLimit : LIMIT_ITEM_PAGE,
  });
  const {
    isDiscountModalOpen,
    setIsDiscountModalOpen,
    discountUserId,
    setDiscountUserId,
    discountValue,
    setDiscountValue,
    discountValueError,
    setDiscountValueError,
    isDiscount,
    setIsDiscount,
  } = useDiscount();

  const { isCreditModalOpen, setIsCreditModalOpen, setUserId, userId } =
    useCredit();

  const { data: currentUserData } = useGetCurrentUserQuery();

  const { loading, error, data, refetch } = useQuery(QUERY_USERS, {
    variables: {
      offset: (filter.page - 1) * filter.itemsLimit,
      limit: filter.itemsLimit,
      search: filter.search,
    },
    fetchPolicy: 'cache-and-network',
  });

  const [setUserDiscountMutation] = useMutation(
    MUTATION_USER_DISCOUNT_MANAGMENT,
  );

  const [verifyPassportDataMutation] = useVerifyPassportDataMutation();

  const [verifyLegalDataMutation] = useVerifyLegalDataMutation();

  useEffect(() => {
    if (data?.users?.rows) {
      setUsers(data.users.rows);
    }
  }, [data]);

  useEffect(() => {
    refetch({
      variables: {
        offset: (filter.page - 1) * filter.itemsLimit,
        limit: filter.itemsLimit,
        search: filter.search,
      },
    } as any);
  }, [filter, refetch]);

  if (error) enqueueSnackbar(error.message, { variant: 'error' });

  const handlerPagination = (_: {}, page: number) => {
    setFilter((state) => ({
      ...state,
      page: page,
    }));
  };

  const [updateCreditLimitForUserMutation] =
    useUpdateCreditLimitForUserMutation();

  const handleSetCreditLimit = ({ userId, value }: HandleSetCreditLimitArg) => {
    updateCreditLimitForUserMutation({
      variables: {
        userId: userId,
        value: value,
      },
    })
      .then(() => {
        refetch();
      })
      .catch(() => {});
  };

  const handlerSearchForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFilter((state) => ({
      ...state,
      search: searchInputValue,
      page: 1,
    }));

    refetch({
      offset: 0,
      search: searchInputValue,
    });
  };

  return (
    <>
      <Typography variant='h2' align='center'>
        {t('app.customers')}
      </Typography>
      <div className={classes.filters}>
        <form className={classes.filtersSearch} onSubmit={handlerSearchForm}>
          <FormControl className={classes.filtersItemSelect}>
            <BoxCentered pl={2}>
              <span style={{ paddingLeft: '24px' }}>{t('app.show')}</span>
              <Select
                displayEmpty
                disableUnderline
                value={filter.itemsLimit}
                onChange={(e) => {
                  localStorage.setItem(
                    LOCAL_STORAGE_KEYS.ITEMS_LIMIT,
                    e.target.value as string,
                  );
                  setFilter(
                    (state) =>
                      ({
                        ...state,
                        itemsLimit: e.target.value,
                        page: 1,
                      } as typeof filter),
                  );
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
                <MenuItem value={500}>500</MenuItem>
              </Select>
            </BoxCentered>
          </FormControl>
          <Input
            className={classes.filtersSearchField}
            onChange={(e) => setSearchInputValue(e.target.value)}
            value={searchInputValue}
            disableUnderline
            placeholder={t('app.search')}
          />
          <Button
            className={classes.filtersSearchSubmit}
            variant='contained'
            type='submit'
            aria-label='search'
          >
            <SearchIcon />
          </Button>
        </form>
      </div>
      <If condition={loading}>
        <Then>
          <div>
            <ShowLoadingText />
          </div>
        </Then>
        <Else>
          <TableContainer component={Paper}>
            <Table aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell align='center'>№</TableCell>
                  <TableCell align='center'>{t('app.date')}</TableCell>
                  <TableCell align='center'>{t('app.fullName')}</TableCell>
                  <TableCell align='center'>{t('app.balance')}</TableCell>
                  <TableCell align='center'>{t('app.company')}</TableCell>
                  <TableCell align='center'>{t('app.address')}</TableCell>
                  <TableCell align='center'>{t('app.phone')}</TableCell>
                  <TableCell align='center'>Email</TableCell>
                  <TableCell align='center'>{t('app.verification')}</TableCell>
                  <TableCell align='center'>{t('app.creditLimit')}</TableCell>
                  <TableCell align='center'>{t('app.discount')}</TableCell>
                  <TableCell align='center'>
                    {t('app.storageDiscount')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(users as User[]).map((user) => {
                  return (
                    <UserRowItem
                      key={user.id}
                      user={user}
                      setDiscountUserId={setDiscountUserId}
                      setIsDiscountModalOpen={setIsDiscountModalOpen}
                      setDiscountValue={setDiscountValue}
                      verifyPassportDataMutation={verifyPassportDataMutation}
                      verifyLegalDataMutation={verifyLegalDataMutation}
                      handleSetCreditLimit={handleSetCreditLimit}
                      isCreditModalOpen={isCreditModalOpen}
                      setIsCreditModalOpen={setIsCreditModalOpen}
                      setUserId={setUserId}
                      role={currentUserData?.currentUser?.role}
                      setIsDiscount={setIsDiscount}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Else>
      </If>
      <Pagination
        className={classes.pagination}
        count={data ? Math.ceil(data.users.count / filter.itemsLimit) : 1}
        page={filter.page}
        onChange={handlerPagination}
        color='primary'
      />
      <ModalDiscount
        isDiscountModalOpen={isDiscountModalOpen}
        setIsDiscountModalOpen={setIsDiscountModalOpen}
        discountUserId={discountUserId}
        users={users}
        discountValue={discountValue}
        setDiscountValueError={setDiscountValueError}
        setDiscountValue={setDiscountValue}
        discountValueError={discountValueError}
        classes={classes}
        setDiscountUserId={setDiscountUserId}
        setUserDiscountMutation={setUserDiscountMutation}
        enqueueSnackbar={enqueueSnackbar}
        setUsers={setUsers}
        isDiscount={isDiscount}
      />
      <ModalCredit
        isCreditModalOpen={isCreditModalOpen}
        setIsCreditModalOpen={setIsCreditModalOpen}
        users={users}
        classes={classes}
        userId={userId}
        handleSetCreditLimit={handleSetCreditLimit}
      />
    </>
  );
}
