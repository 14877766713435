import { gql } from '@apollo/client';

export const MUTATION_USER_DISCOUNT_MANAGMENT = gql`
  mutation UserDiscountManagement(
    $userId: Int!
    $discount: Float
    $whFeePersonalDiscount: Float
  ) {
    userDiscountManagement(
      userId: $userId
      discount: $discount
      whFeePersonalDiscount: $whFeePersonalDiscount
    ) {
      address
      company
      discount
      email
      id
      name
      phone
      whFeePersonalDiscount
    }
  }
`;
