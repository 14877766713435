import { gql } from '@apollo/client';
import { DELIVERY_COUNTRY } from '../fragments/deliveryCountryFragment';

export const QUERY_WAREHOUSE_SHIPMENT_ORDERS = gql`
  ${DELIVERY_COUNTRY}
  query getWarehouseShipmentOrders($data: WarehouseShipmentOrdersOptions) {
    getWarehouseShipmentOrders(data: $data) {
      count
      rows {
        id
        receiver {
          id
          name
          city
          country {
            ...FragmentDeliveryCountry
          }
        }
        trackNumber
        carrierCode
        carrierService
        declaredAmount
        actualAmount
        additionalInsurance
        insuranceAmount
        signature
        wrap
        message
        senderMark
        pathNameFile
        vatKey
        vatValue
        deliveryStatus {
          id
          name
          explain
        }
        paymentStatus {
          id
          name
          explain
        }
        products {
          price
          quantity
          relatedProduct {
            id
            name
            barcodeId
            sku
          }
        }
        warehouseStatus
        barcodeId
        orderCostCurrency
        createdAt
        clientUser {
          id
          name
        }
        comment
      }
    }
  }
`;
