import { createMuiTheme } from '@material-ui/core';

const defaultTheme = createMuiTheme();

export default createMuiTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 16,
      },
    },
    MuiCssBaseline: {
      '@global': {
        '.cursorPointer': {
          cursor: 'pointer',
          textDecoration: 'underline',
        },
      },
    },
    MuiTableCell: {
      root: {
        fontSize: '14px',
        lineHeight: '20px',
        padding: 4,
        color: '#000000',
        border: '1px solid #d4d4d4',
        borderBottom: 'none',
      },
      head: {
        fontSize: '16px',
        padding: 4,
        lineHeight: '24px',
        marginBottom: 12,
        fontWeight: 500,
        color: '#000000',
        border: '1px solid #d4d4d4',
        background: '#e5e5e5',
      },
    },
    // @ts-ignore
    MuiPagination: {
      root: {
        '& .Mui-selected': {
          backgroundColor: '#000000',

          '&:hover': {
            backgroundColor: '#393939',
          },
        },
      },
    },
    MuiIconButton: {
      root: {
        color: '#333333',
      },
    },
    Divider: {
      root: {
        backgroundColor: '#e0e0e0',
      },
    },
    MuiButton: {
      root: {
        alignSelf: 'flex-start',
        fontSize: '15px',
        fontWeight: 500,
        lineHeight: '24px',
        textTransform: 'none',
        width: 'auto',
        borderRadius: 'none',
        boxShadow: 'none',
      },
      contained: {
        padding: '12px 24px',
        boxShadow: 'none',
        backgroundColor: '#333333',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#1d1d1d',
        },
      },
      outlined: {
        color: '#000000',
        border: '1px solid #000000',
        padding: '12px 20px',
        minWidth: '177px',
        '&:hover': {
          borderColor: '#bfb3b3',
        },
      },
      containedSecondary: {
        boxShadow: 'none',
        backgroundColor: '#bdbdbd',
        color: '#4f4f4f',
        '&:hover': {
          backgroundColor: '#c1c1c1',
        },
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        color: '#000000',

        '&$checked': {
          color: '#000000',
        },
      },
    },
    MuiRadio: {
      colorPrimary: {
        color: '#000000',

        '&$checked': {
          color: '#000000',
        },
      },
    },
    MuiTypography: {
      h2: {
        fontWeight: 500,
        fontSize: '30px',
        lineHeight: '35px',
        color: '#333333',

        [defaultTheme.breakpoints.down('xs')]: {
          fontSize: '25px',
          lineHeight: '30px',
        },
      },
      h3: {
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '24px',
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
    MuiInputLabel: {
      root: {
        fontWeight: 500,
        marginBottom: 8,
        color: '#333333',
        fontSize: '16px',
        lineHeight: '24px',
      },
      formControl: {
        transform: 'none',
        position: 'static',
      },
    },
    MuiSelect: {
      root: {
        border: 'none',
      },
      icon: {
        color: '#000000',
      },
    },
    MuiInput: {
      root: {
        border: '1px solid #4f4f4f',
      },
      input: {
        height: 'auto',
        padding: '13px 16px',
        fontSize: '14px',
        color: '#000',
        lineHeight: '20px',
      },
      formControl: {
        'label + &': {
          marginTop: 0,
        },
      },
    },
    MuiAutocomplete: {
      input: {
        height: 'auto',
        padding: '13px 16px !important',
        fontSize: '14px',
        color: '#000',
        lineHeight: '20px',
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
});
