import React, { useEffect, useState } from 'react';
import {
  Button,
  Container,
  FormControl,
  Input,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import { DATE_FORMAT, LOCAL_STORAGE_KEYS } from '../../../utils/constants';
import {
  Product,
  useGetCurrentUserQuery,
  useGetMyProductListLazyQuery,
  useGetWarehousesListQuery,
} from '../../../generated/graphql';
import { useSnackbar } from 'notistack';
import { ShowLoadingText } from '../../../utils/helperComponents';
import { LIMIT_ITEM_PAGE, TRoutes } from '../../../utils/helpers';
import iconBarcode from '../../../assets/img/icon_barcode.svg';
import SearchIcon from '@material-ui/icons/Search';
import { Link } from 'react-router-dom';
import { BoxCentered } from '../../../components/BoxCentered/BoxCentered';
import { useDebouncedCallback } from 'use-debounce/lib';
import { Pagination } from '@material-ui/lab';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

interface IMyProductsFilter {
  page: number;
  itemsLimit: number;
  warehouseId: string;
  search: string;
}

const itemsLimit = localStorage.getItem(LOCAL_STORAGE_KEYS.ITEMS_LIMIT);
const warehouseId = localStorage.getItem(LOCAL_STORAGE_KEYS.WAREHOUSE_ID);

const MyProductList: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [products, setProducts] = useState<Product[]>([]);
  const [loadingAction, setLoadingAction] = useState(false);
  const [warehouseList, setWarehouseList] = useState<any>([]);

  const { t } = useTranslation();

  const [filter, setFilter] = useState<IMyProductsFilter>({
    page: 1,
    itemsLimit: itemsLimit ? +itemsLimit : LIMIT_ITEM_PAGE,
    warehouseId: warehouseId ? warehouseId : '',
    search: '',
  });

  const { data: currentUserData } = useGetCurrentUserQuery();

  const [getMyProductListLazyQuery, { data: dataProducts, loading, error }] =
    useGetMyProductListLazyQuery({
      fetchPolicy: 'network-only',
    });

  const { data: dataWarehouses, error: errorWarehouses } =
    useGetWarehousesListQuery();

  useEffect(() => {
    if (!dataProducts?.getMyProductList?.rows) return;

    dataProducts?.getMyProductList?.rows &&
      setProducts(dataProducts?.getMyProductList?.rows as Product[]);

    // eslint-disable-next-line
  }, [dataProducts]);

  useEffect(() => {
    dataWarehouses?.getWarehousesList?.rows &&
      setWarehouseList(dataWarehouses?.getWarehousesList?.rows);
  }, [dataWarehouses]);

  useEffect(() => {
    refetchMyProductList();
    // eslint-disable-next-line
  }, [filter]);

  error?.message && enqueueSnackbar(error.message, { variant: 'error' });
  errorWarehouses?.message &&
    enqueueSnackbar(errorWarehouses.message, { variant: 'error' });

  const refetchMyProductList = useDebouncedCallback(() => {
    setLoadingAction(true);
    getMyProductListLazyQuery({
      variables: {
        limit: filter.itemsLimit,
        offset: (filter.page - 1) * filter.itemsLimit,
        search: filter.search,
        ...(filter.warehouseId &&
          currentUserData?.currentUser?.role === 'SUPER_ADMIN' && {
            warehouseId: filter.warehouseId,
          }),
      },
    });
    setLoadingAction(false);
  }, 400);

  return (
    <Container>
      <Typography variant='h2' align='center'>
        {(t('app.productsWarehouse') || '').toUpperCase()}
      </Typography>

      <div className={classes.filters}>
        {currentUserData?.currentUser?.role === 'SUPER_ADMIN' ? (
          <FormControl className={classes.filtersItemSelect}>
            <Select
              displayEmpty
              disableUnderline
              disabled={loading}
              value={filter.warehouseId}
              onChange={(e) => {
                localStorage.setItem(
                  LOCAL_STORAGE_KEYS.WAREHOUSE_ID,
                  e.target.value as string,
                );
                setFilter((state) => ({
                  ...state,
                  warehouseId: e.target.value as string,
                }));
              }}
            >
              <MenuItem value={''}>{t('app.allWarehouses')}</MenuItem>
              {warehouseList?.map((item: any) => (
                <MenuItem key={item.code} value={item.id}>
                  {item.code}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}

        <FormControl className={classes.filtersItemSelect}>
          <BoxCentered pl={2}>
            <span>{t('app.show')}</span>
            <Select
              displayEmpty
              disableUnderline
              value={filter.itemsLimit}
              onChange={(e) => {
                localStorage.setItem(
                  LOCAL_STORAGE_KEYS.ITEMS_LIMIT,
                  //@ts-ignore
                  e.target.value,
                );
                //@ts-ignore
                setFilter((state) => ({
                  ...state,
                  itemsLimit: e.target.value,
                  page: 1,
                }));
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={500}>500</MenuItem>
            </Select>
          </BoxCentered>
        </FormControl>
        <form
          className={classes.filtersSearch}
          onSubmit={(e) => {
            e.preventDefault();
            refetchMyProductList();
          }}
        >
          <Input
            placeholder={t('app.enterText')}
            className={classes.filtersSearchField}
            onChange={(e) => {
              setFilter((state) => ({
                ...state,
                search: e.target.value,
                page: 1,
              }));
            }}
            value={filter.search}
            disableUnderline
          />
          <Button
            className={classes.filtersSearchSubmit}
            variant='contained'
            type='submit'
            aria-label='search'
          >
            <SearchIcon />
          </Button>
        </form>
      </div>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center'>ID</TableCell>
              <TableCell align='center'>{t('app.created')}</TableCell>
              <TableCell align='center'>{t('app.productName')}</TableCell>
              <TableCell align='center'>SKU</TableCell>
              <TableCell align='center'>{t('app.inTransit')}</TableCell>
              <TableCell align='center'>{t('app.available')}</TableCell>
              <TableCell align='center'>{t('app.reserved')}</TableCell>
              <TableCell align='center'>{t('app.price')}</TableCell>
              <TableCell align='center'>{t('app.action')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(!dataProducts || loading || loadingAction) && (
              <TableRow>
                <TableCell colSpan={9} align='center'>
                  <ShowLoadingText name='товаров' />
                </TableCell>
              </TableRow>
            )}

            {!loading &&
              !loadingAction &&
              products?.map((product) => {
                return (
                  product && (
                    <TableRow>
                      <TableCell align='center'>
                        <Link
                          to={TRoutes.WAREHOUSE_MY_PRODUCT_DETAILS.replace(
                            ':id',
                            product.id.toString(),
                          )}
                        >
                          {product.barcodeId?.substring(
                            0,
                            product.barcodeId?.length - 4,
                          )}
                        </Link>
                      </TableCell>
                      <TableCell align='center'>
                        {moment(Number(product?.createdAt)).format(DATE_FORMAT)}
                      </TableCell>
                      <TableCell align='center'>{product.name}</TableCell>
                      <TableCell align='center'>{product.sku}</TableCell>
                      <TableCell align='center'>
                        {product.productAvailabilityQuantity?.inTransitQuantity}
                      </TableCell>
                      <TableCell align='center'>
                        {product.productAvailabilityQuantity?.availableQuantity}
                      </TableCell>
                      <TableCell align='center'>
                        {product.productAvailabilityQuantity?.reservedQuantity}
                      </TableCell>
                      <TableCell align='center'>
                        {`${product?.declaredValue} ${product?.currency}`}
                      </TableCell>
                      <TableCell align='center'>
                        <Select value='Действие'>
                          <MenuItem
                            value='Действие'
                            disabled
                            style={{ display: 'none' }}
                          >
                            {t('app.action')}
                          </MenuItem>
                          <MenuItem
                            disableGutters
                            divider
                            className={classes.actionMenuItem}
                          >
                            <Link
                              className={classes.actionLink}
                              to={TRoutes.WAREHOUSE_MY_PRODUCT_DETAILS.replace(
                                ':id',
                                product.id.toString(),
                              )}
                            >
                              <SearchIcon
                                className={`${classes.actionIcon} ${classes.actionIconWatch}`}
                              />
                              {t('app.view')}
                            </Link>
                          </MenuItem>
                          <MenuItem
                            disableGutters
                            divider
                            className={classes.actionMenuItem}
                          >
                            <Tooltip title={t('app.clickOpenBarcode')}>
                              <a
                                href={`${process.env.REACT_APP_API_DOMAIN}/api/getBarcode/${product?.barcodeId}`}
                                target='_blank'
                                rel='noopener noreferrer'
                                className={classes.actionLink}
                              >
                                <img
                                  src={iconBarcode}
                                  alt='barcode'
                                  className={`${classes.actionIcon}`}
                                  style={{ width: 20, height: 20 }}
                                />
                                {t('app.barcode')}
                              </a>
                            </Tooltip>
                          </MenuItem>
                        </Select>
                      </TableCell>
                    </TableRow>
                  )
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        className={classes.pagination}
        count={
          dataProducts
            ? Math.ceil(
                //@ts-ignore
                dataProducts?.getMyProductList?.count / filter.itemsLimit,
              )
            : 1
        }
        page={filter.page}
        onChange={(_, page) => {
          setFilter((state) => ({
            ...state,
            page: page,
          }));
        }}
        color='primary'
      />
    </Container>
  );
};

export default MyProductList;
