export const MAX_PARCEL_WEIGHT = 150;
export const MAX_PARCEL_DIMENSION = 120;

export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_TIME_FORMAT = 'DD.MM.YYYY H:mm:ss';

export const ERRORS = {
  CANT_CANCEL_PARCEL_WITH_IS_SENT: 'app.cancelOrderError',
  FIELD_REQUIRED: 'app.fillInField',
  POSITIVE_NUMBER: 'app.positiveNumber',
  MAX_VALUE: 'app.maxValue',
};

export const TEXT = {
  GROSS: 'app.grossDefinition',
  NET: 'app.netDefinition',
  SERVICE_IS_NOT_AVAILABLE_YET: 'app.integrationNotImplemented',
};

export const PAYMENT_TYPES: { [key: string]: string } = {
  REFILL: 'app.accountReplenishment',
  REFUND: 'app.refund2',
  ORDER_PAYMENT: 'app.debitForOrder',
  CDEK: 'app.shipmentToWarehouseViaCDEK',
  CANCEL_ADJUSTMENT: 'app.cancelWeightCorrection',
  WEIGHT_ADJUSTMENT: 'app.correction',
  WAREHOUSE_STORAGE: 'app.storageOnWarehouse',
  ADMIN_OPERATION: 'app.manualReplenishment',
};

export const COLORS = {
  GREEN: '#048c04',
  BLUE: '#58a6ff',
  RED: '#ff0000',
  YELLOW: '#ffc749',
  ORANGE: '#ff6e00',
  BLACK: '#000',
  WHITE: '#fff',
  GREY: '#808080',
};

export const CURRENCIES = {
  RUB: 'RUB',
  EUR: 'EUR',
  USD: 'USD',
  GBP: 'GBP',
};

export const LOCAL_STORAGE_KEYS = {
  AUTH_TOKEN: 'authToken',
  IS_SIDEBAR_HIDDEN: 'isSidebarHidden',
  IS_LIST_OPEN_SENDING: 'isListOpenSending',
  IS_LIST_OPEN_PAYMENTS: 'isListOpenPayments',
  IS_LIST_OPEN_SETTINGS: 'isListOpenSettings',
  IS_WAREHOUSE_PRODUCTS_LIST_OPEN: 'isListOpenWarehouseProducts',
  ITEMS_LIMIT: 'itemsLimit',
  ITEMS_LIMIT_RETURNS: 'itemsLimitReturns',
  WAREHOUSE_ID: 'warehouseId',
  WAREHOUSE_ID_ORDER: 'warehouseIdOrder',
  DELIVERY_COUNTRIES: 'deliveryCountries',
  IS_LIST_OPEN_SENDING_TO_WAREHOUSE: 'isListOpenSendingToWarehouse',
  IS_LIST_REPORTS: 'isListOpenReports',
  TAB_VALUE: 'tabValue',
  IS_LIST_OPEN_RETURNS: 'isListOpenReturns',
  ITEMS_LIMIT_SHIPMENTS_ALL_HISTORY: 'itemsLimit',
};

export const UNIT_STATES = {
  UNKNOWN: 'UNKNOWN',
  NEW: 'NEW',
  USED: 'USED',
};

export const EXPORT_REASONS = {
  COMMERCIAL: 'COMMERCIAL',
  GIFT: 'GIFT',
  SAMPLE: 'SAMPLE',
  RETURN: 'RETURN',
  REPAIR: 'REPAIR',
};

export const PAYMENT_OF_TAXES = {
  RECIPIENT: 'RECIPIENT',
  SENDER: 'SENDER',
};

export const EXPORT_REASONS_TRANSLATE = {
  COMMERCIAL: { en: 'COMMERCIAL', ru: 'Продажа' },
  GIFT: { en: 'GIFT', ru: 'Подарок' },
  SAMPLE: { en: 'SAMPLE', ru: 'Образец' },
  RETURN: { en: 'RETURN', ru: 'Возврат' },
  REPAIR: { en: 'REPAIR', ru: 'Возврат для ремонта' },
};

export const PAYMENT_OF_TAXES_IMPORT = {
  CONSIGNEE: { en: 'CONSIGNEE', ru: 'Получатель' },
  SENDER: { en: 'SENDER', ru: 'Отправитель' },
};

export const VATKEY = {
  EMPTY: 'EMPTY', //default
  VAT: 'VAT',
  IOSS: 'IOSS',
  EIN: 'EIN',
};

export const DHL_INVOICE_TYPES = {
  COMMERCIAL: 'commercial',
  PROFORMA: 'proforma',
};

export const INCOTERMS = {
  DAP: 'DAP', // default
  DDP: 'DDP',
  DAT: 'DAT',
  DDU: 'DDU',
};

export const DUTIES_PAYERS = {
  CONSIGNEE: 'consignee', // грузополучатель
  SENDER: 'sender', // аккаунт отправителя
};

export const MAX_LENGTH_DESCRIPTION_DHL = 75;

export const COUNTRIES_CIS = [
  {
    iso: 'AZ',
    name: 'Азербайджанская Республика',
    nameEng: 'Azerbaijan Republic',
    id: 248,
  },
  {
    iso: 'AM',
    name: 'Республика Армения',
    nameEng: 'Respublika Armenia',
    id: 244,
  },
  {
    iso: 'BY',
    name: 'Республика Беларусь',
    nameEng: 'Republic of Belarus',
    id: 253,
  },
  {
    iso: 'KZ',
    name: 'Республика Казахстан',
    nameEng: 'Republic of Kazakhstan',
    id: 339,
  },
  { iso: 'KG', name: 'Кыргызская Республика', nameEng: 'Kyrgyzstan', id: 345 },
  { iso: 'MD', name: 'Республика Молдова', nameEng: 'Moldova', id: 369 },
  { iso: 'RU', name: 'Россия', nameEng: 'Russia', id: 459 },
  { iso: 'TJ', name: 'Республика Таджикистан', nameEng: 'Tajikistan', id: 432 },
  { iso: 'TM', name: 'Туркменистан', nameEng: 'Turkmenistan', id: 441 },
  { iso: 'UZ', name: 'Республика Узбекистан', nameEng: 'Uzbekistan', id: 450 },
  { iso: 'UA', name: 'Украина', nameEng: 'Ukraine', id: 445 },
];

export const DEBOUNCE = 10;

export const WRAP_TYPES = {
  DEFAULT: 'app.defaultPackage',
  BUBBLE: 'app.bubbleWrap',
  BOX_S: 'app.smallBox',
  BOX_M: 'app.mediumBox',
  BOX_L: 'app.largeBox',
};

export const MOVEMENT_OPERATIONS: { [key: string]: string } = {
  REFILL: 'app.refill2',
  WRITE_OFF: 'app.deduction',
  INTERNAL_SWAP: 'app.internalSwap',
  SENDING: 'app.sending',
  ORDER_PICKING: 'app.orderPicking',
  ORDER_CANCEL: 'app.canceling',
  RETURN: 'app.refund',
  UNDECLARED_INBOUND: 'app.undeclaredArrival',
  INBOUND: 'app.inbound',
};

export const REPORT_TYPES: { [key: string]: string } = {
  TRANSACTIONS: 'app.transactionReport',
  PRODUCT: 'app.productReport',
  FULFILLMENT: 'app.fulfillmentReport',
};

export const WAREHOUSE_OPERATIONS: { [key: string]: string } = {
  ARRIVAL_ORDERS: 'app.arriveAtWarehouse',
  FORWARDING: 'app.forwarding',
  FULFILLMENT: 'app.shipmentFromWarehouse',
  RETURNS: 'app.returns',
};

export const warehouseStatuses: { [key: string]: string } = {
  CREATED: 'app.created',
  PROCESSING: 'app.receiving',
  ACCEPTED: 'app.accepted2',
  CANCELED: 'app.cancelled',
};

export const DELIVERY_STATUS_BY_ID: { [key: number]: string } = {
  1: 'app.created',
  3: 'app.inTransit',
  4: 'app.accepted',
  5: 'app.delivered',
  7: 'app.exception',
  9: 'app.canceled',
};

export const PAYMENT_ORDER_STATUS_BY_ID: { [key: number]: string } = {
  1: 'app.awaitingPayment',
  2: 'app.paid',
  3: 'app.partialPayment',
  4: 'app.refund',
  5: 'app.notRequired',
};

export const RETURNS_SHIPMENT_TABLE_CELLS_ARRAY = [
  'app.returnNo',
  'app.shipmentNumber2',
  'app.warehouse',
  'app.cost',
  'app.trackingNumber',
  'app.warehouseStatus',
  'app.acceptedProducts',
  'app.action',
];

export enum sideBarListItems {
  RETURNS = 'returns',
  SENDING = 'sending',
  SENDING_TO_WAREHOUSE = 'sendingToWarehouse',
  PAYMENTS = 'payments',
  REPORTS = 'reports',
  SETTINGS = 'settings',
  WAREHOUSE_PRODUCTS = 'warehouseProducts',
}

export enum TableHeadCellsEnums {
  NUMBER = 'number',
  ID = 'id',
  PRODUCT_SKU = 'productSku',
  PRODUCT_NAME = 'productName',
  QUANTITY = 'quantity',
}

export const SHIPMENT_FROM_WAREHOUSE_TABLE_CELLS_ARRAY = [
  'app.number',
  'app.customer',
  'app.product',
  'app.cost',
  'app.trackingNumberLabel',
  'app.status',
  'app.payment',
  'app.action',
];

export enum colorsEnum {
  RED = 'red',
  GREEN = 'green',
  BLUE = 'blue',
}

export enum deliveryExplainEnum {
  CANCELED = 'Отменен',
}

export const HISTORY_OF_MOVEMENT_TABLE_CELLS_ARRAY = [
  'app.dateTime',
  'app.operationType',
  'app.details',
  'app.quantity',
];

export const UNDECLARED_ARRIVAL_TABLE_CELLS_ARRAY = [
  'app.ID',
  'app.date',
  'app.orderClient',
  'app.to',
  'app.trackingNumber',
  'app.warehouseStatus',
  'app.acceptedProducts',
  'app.action',
];

export const SWAP_HISTORY_TABLE_CELLS_ARRAY = [
  'app.ID',
  'app.date',
  'app.type',
  'app.details',
  'app.productID',
  'app.quantitySm',
  'app.from2',
  'app.to',
];
