import React from 'react';
import useStyles from './styles';
import { Box } from '@material-ui/core';
import { IAdditionalService } from './types';
import { Wrap_Type } from '../../../generated/graphql';

export const AdditionalService = ({
  comment,
  message,
  wrap,
  giftWrap,
}: IAdditionalService) => {
  const classes = useStyles();
  return (
    <Box className={classes.boxWrapper}>
      {comment && <Box className={classes.boxComment}>Comment</Box>}
      {message && <Box className={classes.boxGift}>Gift message</Box>}
      {wrap !== Wrap_Type.Default && (
        <Box className={classes.boxPackaging}>Packaging</Box>
      )}
      {giftWrap && <Box className={classes.boxGiftWrap}>Gift wrap</Box>}
    </Box>
  );
};
