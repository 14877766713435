import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../utils/constants';

const drawerWidth = 266;
const drawerWidthHidden = 100;

const styles = (isSidebarHidden: boolean) =>
  makeStyles((theme) => ({
    root: {
      display: 'flex',
    },
    logoLink: {
      display: 'flex',
      textDecoration: 'none',
    },
    drawer: {
      [theme.breakpoints.up('lg')]: {
        width: isSidebarHidden ? drawerWidthHidden : drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: isSidebarHidden ? drawerWidthHidden : drawerWidth,
      backgroundColor: '#292D30',
      [theme.breakpoints.down('xs')]: {
        width: 320,
      },
    },
    headerIconWrap: {
      width: 24,
      height: 24,
      margin: '0 5px',
      padding: 0,
      color: COLORS.BLACK,
    },
    appBar: {
      backgroundColor: 'transparent',

      [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${
          isSidebarHidden ? drawerWidthHidden : drawerWidth
        }px)`,
        marginLeft: isSidebarHidden ? drawerWidthHidden : drawerWidth,
      },
      boxShadow: 'none',
    },
    toggleMenu: {
      padding: '0',
      margin: '0',
    },
    toolbar: {
      minHeight: 'auto',
      padding: '24px 20px',
    },
    signOut: {
      minWidth: 'auto',
      color: '#828282',
      lineHeight: '20px',
      padding: 0,
      textTransform: 'none',
      fontWeight: 'normal',
    },
    logo: {
      marginTop: '32px',
      marginLeft: '24px',
      fontSize: '32px',
      lineHeight: '44px',
      fontWeight: 700,
      fontFamily: '"Montserrat", sans-serif',
      color: COLORS.WHITE,
      letterSpacing: '1px',
    },
    logoInner: {
      color: '#a3b4d2',
    },
    logoDescriprion: { fontSize: '24px' },
    logoWithHiddenSidebar: {
      maxHeight: 45,
      margin: '28px auto auto',
    },
    content: {
      flexGrow: 1,
      minHeight: '100vh',
      width: 'calc(100% - 345px)',
      padding: 20,
      paddingLeft: 32,
      paddingRight: 16,
      paddingTop: 44,

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        paddingLeft: 10,
        paddingRight: 10,
      },
    },
    container: {
      maxWidth: '100%',
      padding: 0,
      height: '100%',
    },
    person: {
      display: 'flex',
      alignItems: 'center',
      color: '#606060',
      fontWeight: 300,
      fontSize: '18px',
      lineHeight: '100%',
      textDecoration: 'none',
      marginRight: '5px',
      '&:hover': {
        textDecoration: 'underline',
        '& $personIcon': {
          color: '#606060',
        },
      },
    },
    personIcon: {
      color: COLORS.BLACK,
      marginRight: '14px',
      padding: '0px',
      height: '30px',
    },
    sidebarToggler: {
      cursor: 'pointer',
      color: COLORS.WHITE,
      display: 'flex',
      justifyContent: 'center',
      padding: '15px 0',
    },

    userSettingsSelect: {
      border: 'none',
      marginRight: '40px',

      '& .MuiSelect-root': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px',
        boxSizing: 'border-box',
        color: '#606060',
        fontSize: '18px',
        lineHeight: '100%',
        fontWeight: '300',
      },
      '& .MuiSvgIcon-root': {
        width: '24px',
        height: '24px',
      },
      '& .MuiSvgIcon-root.MuiSelect-icon': {
        display: 'none',
      },
      '&::before': {
        display: 'none',
      },
    },
    headerWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    language: {
      color: '#606060',
      fontWeight: 300,
      fontSize: '18px',
    },
    languageSelect: {
      marginRight: '14px',
      border: 'none',
      '& .MuiSelect-root': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px',
        boxSizing: 'border-box',
      },
      '& .MuiPaper-root': {
        top: '40px',
      },
      '& .MuiSvgIcon-root': {
        width: '24px',
        height: '24px',
      },
      '& .MuiSvgIcon-root.MuiSelect-icon': {
        display: 'none',
      },
      '&::before': {
        display: 'none',
      },
    },
  }))();

export default styles;
