import {
  ShipmentWarehouseStatus,
  WarehouseShipmentOrder,
} from '../../../generated/graphql';
import { TRoutes } from '../../../utils/helpers';
import SearchIcon from '@material-ui/icons/Search';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import iconBarcode from '../../../assets/img/icon_barcode.svg';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { colorsEnum, deliveryExplainEnum } from '../../../utils/constants';

export const getActionOptions = (
  order: WarehouseShipmentOrder,
  isWithLabel: boolean,
  openModalForCancelHandler: () => void,
  sendWarehouseSipmentOrderHandler: (id: string) => () => void,
) => {
  return [
    {
      link: '#',
      Icon: LocalShippingIcon,
      title: 'app.send',
      color: colorsEnum.BLUE,
      tooltipText: 'app.clickToSendOrder',
      onClick: sendWarehouseSipmentOrderHandler(
        order?.id?.toString() as string,
      ),
      disabled: order.warehouseStatus !== ShipmentWarehouseStatus.Picked,
    },
    {
      link: `${
        TRoutes.PARCELS_FROM_WAREHOUSE_DETAILS_WITHOUT_ID
      }${order?.id?.toString()}`,
      Icon: SearchIcon,
      title: 'app.view',
      color: colorsEnum.GREEN,
      tooltipText: 'app.clickToOpenViewShipments',
    },
    {
      link: `api/getBarcode/${order?.barcodeId}`,
      imgSrc: iconBarcode,
      title: 'app.barcode',
      target: '_blank',
      tooltipText: 'app.clickToOpenBarcode',
    },
    {
      link: `api/getReceipt/${isWithLabel ? 'for_admin_' : ''}${
        order.pathNameFile
      }`,
      Icon: SaveIcon,
      title: 'app.label',
      target: '_blank',
      disabled: order.warehouseStatus !== ShipmentWarehouseStatus.Shipped,
      tooltipText: 'app.clickToDownloadWaybill',
    },
    {
      link: `#`,
      Icon: CancelIcon,
      title: 'app.cancel',
      onClick: openModalForCancelHandler,
      disabled:
        order?.deliveryStatus?.explain === deliveryExplainEnum.CANCELED ||
        order?.warehouseStatus === ShipmentWarehouseStatus.Unshipped,

      color: colorsEnum.RED,
      tooltipText: 'app.clickToCancelOrder',
    },
  ];
};
