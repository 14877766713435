import { DEPARTURE_DATE_FILTER } from '../../../utils/helpers';

export const filterSelectDepartureDateOptions = [
  { title: 'app.period', value: '' },
  { title: 'app.forAllTime', value: DEPARTURE_DATE_FILTER.ALL_TIME },
  {
    title: 'app.lastNumberDays',
    nDays: 7,
    value: DEPARTURE_DATE_FILTER.LAST_7,
  },
  {
    title: 'app.lastNumberDays',
    nDays: 30,
    value: DEPARTURE_DATE_FILTER.LAST_30,
  },
];
